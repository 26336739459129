import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import ErrorMessage from "components/ErrorMessage";
import { checkAIDescriptionForBrand } from "actions/aiDesignStudio";
import CurvedImage from "assets/images/text-sample-curved.png";
import ObliqueImage from "assets/images/text-sample-oblique.png";
import TextNumberImage from "assets/images/text-number.png";
import CircularTextImage from "assets/images/circular-text.png";
import {
  Container,
  FontPickerWrapper,
} from "styles/components/ArtWork/browseLibrary";

const BrowseTextDrawer = ({ onDrop, plus }) => {
  const dispatch = useDispatch();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  const [trademarkError, setTrademarkError] = useState(false);
  // const [color, setColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Open Sans");
  const [showAllFonts, setShowAllFonts] = useState(false);
  // const [fontSize, setFontSize] = useState(20);
  // const [fontBold, setFontBold] = useState(undefined);
  // const [fontItalic, setFontItalic] = useState(undefined);
  // const [fontUnderline, setFontUnderline] = useState(undefined);
  // const [fontStrikeThrough, setFontStrikeThrough] = useState(undefined);
  // const [fontOverline, setFontOverline] = useState(undefined);
  const googleFonts = useSelector((state) => state?.googleFonts?.data);
  const token = useSelector((state) => state?.auth?.profile?.token);

  const fonts = googleFonts?.items?.filter(
    (d) => d?.version === "v15" || d?.version === "v14"
  );
  // const [inputValue, setInputValue] = React.useState(fontFamily || "");

  return (
    <>
      <Container style={{ padding: "20px", overflow: "auto" }}>
        <h1
          style={{
            color: "#fff",
            textAlign: "center",
            margin: "0 0 20px",
          }}
        >
          ADD A TEXT BOX
        </h1>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <img
            src={CurvedImage}
            alt=""
            style={{ width: 100, cursor: "pointer" }}
            onClick={() => {
              onDrop({
                text: "Curved Text",
                fontFamily: fonts[0]?.family,
                color: "#b2b2b2",
                public_id: `${moment().millisecond()}${fonts[0]?.family}`,
                secure_url: fonts[0]?.family,
                curved: true,
              });
            }}
          />
          {/* <img
            src={ObliqueImage}
            alt=""
            style={{ width: 100, cursor: "pointer" }}
            onClick={() => {
              onDrop({
                text: "Oblique",
                fontFamily: fonts[0]?.family,
                color: "#b2b2b2",
                public_id: `${moment().millisecond()}${fonts[0]?.family}`,
                secure_url: fonts[0]?.family,
                oblique: true,
              });
            }}
          /> */}

          <img
            src={CircularTextImage}
            alt=""
            style={{ width: 100, cursor: "pointer" }}
            onClick={() => {
              onDrop({
                text: "Circular Text",
                fontFamily: fonts[0]?.family,
                color: "#b2b2b2",
                public_id: `${moment().millisecond()}${fonts[0]?.family}`,
                secure_url: fonts[0]?.family,
                circular: true,
              });
            }}
          />

          <img
            src={TextNumberImage}
            alt=""
            style={{ width: 100, cursor: "pointer" }}
            onClick={() => {
              onDrop({
                textNumber: true,
              });
            }}
          />
        </div>
        <FontPickerWrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              flexWrap: "wrap",
            }}
          >
            {fonts.map((f, i) => {
              if (i > 20 && !showAllFonts) return null;

              return (
                <span
                  className="font-name"
                  key={f?.family}
                  onClick={async () => {
                    setFontFamily(f?.family);

                    const tradeMarkInfo = await dispatch(
                      checkAIDescriptionForBrand({ message: f?.family }, token)
                    );

                    if (tradeMarkInfo?.tradeMarkWordsCount > 0) {
                      setTrademarkError(true);
                      return;
                    }

                    onDrop({
                      text: f?.family,
                      fontFamily: f?.family,
                      color: "#b2b2b2",
                      public_id: `${moment().millisecond()}${f?.family}`,
                      secure_url: f?.family,
                    });
                  }}
                  style={{
                    fontFamily: f?.family,
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "#fff",
                    border:
                      fontFamily === f?.family ? `1px solid black` : "none",
                    padding: "5px",
                  }}
                >
                  {f?.family}
                </span>
              );
            })}
          </div>

          {!showAllFonts && (
            <div style={{ textAlign: "center", padding: "20px 0" }}>
              <Button
                title="Load more..."
                onClick={() => setShowAllFonts(true)}
                containerStyle={{
                  backgroundColor: COLORS.DARK_BLUE,
                  color: COLORS.WHITE,
                  fontWeight: "normal",
                  textTransform: "none",
                  borderRadius: "6px",
                  fontSize: "12px",
                  lineHeight: "1.5",
                  boxShadow: "none",
                }}
              />
            </div>
          )}
        </FontPickerWrapper>
      </Container>
      <Loader open={loaderVisibility} />
    </>
  );
};

BrowseTextDrawer.propTypes = {
  open: PropTypes.bool,
};

export default BrowseTextDrawer;
