import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Tabs from "components/Tabs";
import Loader from "components/Loader";
import ArtworksLibraryItem from "components/ArtWork/ArtworksLibraryItem";
import AdvanceArtFiles from "components/ArtWork/AdvanceArtFiles";
import CustomGraphics from "components/ArtWork/CustomGraphics";
import AIGeneratedImages from "components/ArtWork/AIGeneratedImages";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import LayersIcon from "@material-ui/icons/Layers";
import PanoramaIcon from "@material-ui/icons/Panorama";
import ArrowUp from "assets/images/arrow-up.png";
import Library from "assets/images/library.png";
import Library1 from "assets/images/library1.png";
import Library2 from "assets/images/library2.png";
import Library3 from "assets/images/library3.png";
import PanoramaOutlinedIcon from "@material-ui/icons/PanoramaOutlined";

import {
  getDesigns,
  clearArtWork,
  getBackgroundBurnDesigns,
  getPlusArtFiles,
} from "actions/designer";
import {
  ArtworksLibrary,
  CustomGraphicWrapper,
  Options,
  Option,
  OptionLabel,
} from "styles/components/ArtWork/browseLibrary";

const buttonRow = {
  justifyContent: "space-between",
  display: "flex",
  gridGap: 10,
  flexDirection: "row",
};

const buttonStyles = {
  color: "#fff",
  textAlign: "right",
  fontSize: 18,
  textDecoration: "underline",
  cursor: "pointer",
  paddingBottom: 10,
};

const BrowseLibraryTabs = ({
  onDrop,
  email,
  viewOnly = false,
  scroll = true,
  verify = false,
  libraryFiles,
  setLibraryFiles,
  setActiveTab,
  bbLibraryFiles,
  setBBLibraryFiles,
  plusLibraryFiles,
  setPlusLibraryFiles,
  advancedDesigns,
  setAdvancedDesigns,
  showAdvanceToolTab,
  advancedLibraryFiles,
  printType,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  // const [libraryFiles, setLibraryFiles] = useState(undefined);
  // const [bbLibraryFiles, setBBLibraryFiles] = useState(undefined);
  // const [plusLibraryFiles, setPlusLibraryFiles] = useState(undefined);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const isShirtlyAccount =
    emailid === "support@shirtly.com" || emailid === "mlamothe@docpartners.ca";

  const populateLibraryData = (library) => {
    for (let i = 0; i < library?.resources?.length; i++) {
      const l = library?.resources[i];
      const secureUrl = l.secure_url;
      const splitSecureUrl = secureUrl.split("/");
      if (!!splitSecureUrl?.length) {
        l.name = splitSecureUrl[splitSecureUrl?.length - 1];
      }
    }
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <Options>
        <Option selected={activeTab === 0} onClick={() => setActiveTab(0)}>
          <img src={Library} alt="" style={{ width: "100%" }} />

          {/* <OptionLabel>Library</OptionLabel> */}
        </Option>
        <Option selected={activeTab === 1} onClick={() => setActiveTab(1)}>
          <img src={Library1} alt="" style={{ width: "100%" }} />
          {/* <OptionLabel>AI Images</OptionLabel> */}
        </Option>
        <Option selected={activeTab === 2} onClick={() => setActiveTab(2)}>
          <img src={Library2} alt="" style={{ width: "100%" }} />
          {/* <OptionLabel>Stock Designs</OptionLabel> */}
        </Option>
        <Option selected={activeTab === 3} onClick={() => setActiveTab(3)}>
          <img src={Library3} alt="" style={{ width: "100%" }} />
          {/* <OptionLabel>Saved Designs</OptionLabel> */}
        </Option>
      </Options>

      {activeTab === 0 && (
        <ArtworksLibrary>
          <div style={{ ...buttonRow }}>
            <div
              style={buttonStyles}
              onClick={async () => {
                const data = await dispatch(
                  getDesigns({
                    user: email || emailid,
                    next_cursor:
                      libraryFiles?.cursors[libraryFiles?.cursors?.length - 3],
                  })
                );

                setLibraryFiles({
                  ...data,
                  cursors: libraryFiles?.cursors.slice(
                    0,
                    libraryFiles?.cursors?.length - 1
                  ),
                });
              }}
            >
              {libraryFiles?.cursors?.length > 2 ||
              (libraryFiles?.cursors?.length === 2 &&
                !libraryFiles?.next_cursor)
                ? "Prev Page"
                : ""}
            </div>

            <div
              style={buttonStyles}
              onClick={async () => {
                const data = await dispatch(
                  getDesigns({
                    user: email || emailid,
                    next_cursor:
                      libraryFiles?.cursors[libraryFiles?.cursors?.length - 1],
                  })
                );
                setLibraryFiles({
                  ...data,
                  cursors: data?.next_cursor
                    ? [...libraryFiles?.cursors, data?.next_cursor]
                    : [...libraryFiles?.cursors, undefined],
                });
              }}
            >
              {libraryFiles?.next_cursor ? "Next Page" : ""}
            </div>
          </div>

          <div
            style={{
              gridTemplateColumns: "repeat(2, 1fr)",
              rowGap: 10,
              columnGap: 10,
              marginBottom: 20,
            }}
          >
            {libraryFiles && (
              <ArtworksLibraryItem
                verify={verify}
                libraryFiles={libraryFiles?.resources}
                onSelect={(artwork) => {
                  onDrop({
                    ...artwork,
                    secure_url: _.endsWith(artwork?.secure_url, "jpg")
                      ? _.replace(artwork?.secure_url, ".jpg", ".png")
                      : _.replace(artwork?.secure_url, ".jpeg", ".png"),
                  });
                }}
                viewOnly={viewOnly}
                onDeleteImage={async (publicId) => {
                  const artwork = await dispatch(
                    clearArtWork({
                      user: emailid,
                      publicId,
                    })
                  );
                  if (artwork.error) {
                    return;
                  }

                  setLibraryFiles(undefined);
                }}
              />
            )}
          </div>
          {loaderVisibility && (
            <div>
              <Loader open={loaderVisibility} />
            </div>
          )}
        </ArtworksLibrary>
      )}

      {activeTab === 1 && <AIGeneratedImages onDrop={onDrop} />}

      {activeTab === 2 && (
        <CustomGraphics
          onDrop={onDrop}
          // toggle={toggle}
          printType={printType}
        />
      )}

      {activeTab === 3 && (
        <AdvanceArtFiles
          onDrop={onDrop}
          email={email}
          viewOnly={viewOnly}
          scroll={scroll}
          verify={verify}
          advancedDesigns={advancedDesigns}
          setAdvancedDesigns={setAdvancedDesigns}
          advancedLibraryFiles={advancedLibraryFiles}
        />
      )}

      {/* <Tabs
        activeTab={activeTab}
        onTabChange={async (index) => {
          setActiveTab(index);
          if (index === 1) {
            const library = await dispatch(
              getBackgroundBurnDesigns({
                user: email || emailid,
              })
            );
            if (library.error) {
              return;
            }
            populateLibraryData(library);
            setBBLibraryFiles({
              ...library,
              cursors: library?.next_cursor
                ? [undefined, library?.next_cursor]
                : [],
            });
          } else if (index === 2) {
            const library = await dispatch(
              getPlusArtFiles({
                user: email || emailid,
              })
            );
            if (library.error) {
              return;
            }
            populateLibraryData(library);
            setPlusLibraryFiles({
              ...library,
              cursors: library?.next_cursor
                ? [undefined, library?.next_cursor]
                : [],
            });
          }
          // else if (index === 3) {
          //   const library = await dispatch(
          //     getAdvancedDesigns({
          //       user: email || emailid,
          //     })
          //   );
          //   if (library.error) {
          //     return;
          //   }
          //   populateLibraryData(library);
          //   setAdvancedDesigns({
          //     ...library,
          //     cursors: advancedDesigns?.next_cursor
          //       ? [undefined, advancedDesigns?.next_cursor]
          //       : [],
          //   });
          // }
        }}
        tabs={[
          {
            title: "Library",
            children: (
              <ArtworksLibrary>
                <div style={{ ...buttonRow }}>
                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getDesigns({
                          user: email || emailid,
                          next_cursor:
                            libraryFiles?.cursors[
                              libraryFiles?.cursors?.length - 3
                            ],
                        })
                      );

                      setLibraryFiles({
                        ...data,
                        cursors: libraryFiles?.cursors.slice(
                          0,
                          libraryFiles?.cursors?.length - 1
                        ),
                      });
                    }}
                  >
                    {libraryFiles?.cursors?.length > 2 ||
                    (libraryFiles?.cursors?.length === 2 &&
                      !libraryFiles?.next_cursor)
                      ? "Prev Page"
                      : ""}
                  </div>

                  <div
                    style={buttonStyles}
                    onClick={async () => {
                      const data = await dispatch(
                        getDesigns({
                          user: email || emailid,
                          next_cursor:
                            libraryFiles?.cursors[
                              libraryFiles?.cursors?.length - 1
                            ],
                        })
                      );
                      setLibraryFiles({
                        ...data,
                        cursors: data?.next_cursor
                          ? [...libraryFiles?.cursors, data?.next_cursor]
                          : [...libraryFiles?.cursors, undefined],
                      });
                    }}
                  >
                    {libraryFiles?.next_cursor ? "Next Page" : ""}
                  </div>
                </div>

                <div
                  style={{
                    gridTemplateColumns: "repeat(3, 1fr)",
                    rowGap: 10,
                    columnGap: 10,
                  }}
                >
                  {libraryFiles && (
                    <ArtworksLibraryItem
                      verify={verify}
                      libraryFiles={libraryFiles?.resources}
                      onSelect={(artwork) => {
                        onDrop({
                          ...artwork,
                          secure_url: _.endsWith(artwork?.secure_url, "jpg")
                            ? _.replace(artwork?.secure_url, ".jpg", ".png")
                            : _.replace(artwork?.secure_url, ".jpeg", ".png"),
                        });
                      }}
                      viewOnly={viewOnly}
                      onDeleteImage={async (publicId) => {
                        const artwork = await dispatch(
                          clearArtWork({
                            user: emailid,
                            publicId,
                          })
                        );
                        if (artwork.error) {
                          return;
                        }

                        setLibraryFiles(undefined);
                      }}
                    />
                  )}
                </div>
                {loaderVisibility && (
                  <div>
                    <Loader open={loaderVisibility} />
                  </div>
                )}
              </ArtworksLibrary>
            ),
          },

          isShirtlyAccount
            ? {
                title: "AI Images",
                children: <AIGeneratedImages onDrop={onDrop} />,
              }
            : null,
          {
            title: "Stock Designs",
            children: (
              <CustomGraphics
                onDrop={onDrop}
                // toggle={toggle}
                printType={printType}
              />
            ),
          },
        ]}
      /> */}
    </div>
  );
};

export default BrowseLibraryTabs;
